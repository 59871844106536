import React from "react";

const Executors = ({ executors = [] }) => {
  if (executors.length === 0) return null;

  const displayData = () => {
    return (
      <table>
        <tbody>
          {executors.map((each, idx) => {
            let text = `Imprumutul cu nr: ${each.nrcontract} se afla
            in executare silita.`;

            if (each.membru_girat) {
              text = `Imprumutul cu nr: ${each.nrcontract} pentru ${each.membru_girat} se afla
              in executare silita.`;
            }

            return (
              <tr key={idx}>
                <td>
                  <p>
                    {++idx}. {text}
                  </p>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  };

  return (
    <div className="cta cta-1 cta--horizontal boxed boxed--border my-0 py-0 px-0 mx-0">
      <div className="row ">
        <div
          className="col-lg-12 col-md-12 bg--primary"
          style={{ backgroundColor: "#fd7e14" }}
        >
          <div className="row">
            <div className="col">
              <h4 className="mb-1 py-2  ml-4 pl-0 text-left">
                Dosare la executor
              </h4>
            </div>
          </div>
        </div>
      </div>
      {displayData()}
    </div>
  );
};

export default Executors;
