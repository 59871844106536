import React from "react";
import { Link } from "react-router-dom";
import { Loading } from "../../../components";
import queryString from "query-string";
import { checkStatusPaymentOfSmartPay } from "../../../services/PayService";

const Smartpay = () => {
  const [title, setTitle] = React.useState("");

  const [loading, setLoading] = React.useState(true);
  const location = window.location;
  const query = queryString.parse(location.search); // returns the query object

  React.useEffect(() => {
    if (query?.paymentId) {
      checkStatusPaymentOfSmartPay(query?.paymentId)
        .then((response) => {
          if (
            response?.payload &&
            (response?.payload === "ACCP" ||
              response?.payload === "ACSP" ||
              response?.payload === "ACSC")
          ) {
            setTitle(
              "Plata a fost efectuata si va fi procesata de un operator in urmatoarea zi lucratoare. Multumim!"
            );
          } else if (
            response?.payload &&
            (response?.payload === "RCVD" ||
              response?.payload === "PDNG" ||
              response?.payload === "ACTC")
          ) {
            setTitle(
              "Va rugam, urmariti instructiunile din aplicatia bancii pentru a finaliza plata."
            );
          } else {
            setTitle("Plata nu a putut fi procesata.");
          }
        })
        .catch(() => {
          setTitle("A survenit o eroare, va rugam sa ne contactati!");
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [query]);

  if (loading) {
    return <Loading />;
  }

  return (
    <section className="text-center">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-10 col-lg-8">
            <h2>{title}</h2>
          </div>
        </div>
      </div>
      <Link className="btn btn--primary-1 mt-5" to="/">
        <span className="btn__text">Inapoi la pagina principala</span>
      </Link>
    </section>
  );
};

export default Smartpay;
