import Api from "./Api";

export const getProfile = () => {
  return Api({
    method: "GET",
    url: "/users/getProfile",
  });
};

export const updateProfile = (type, value) => {
  return Api({
    method: "POST",
    url: "/users/updateProfileContact",
    data: {
      type,
      value,
    },
  });
};

export const getUsername = () => {
  return Api({
    method: "GET",
    url: "/users/getUsernameAlias",
  });
};

export const updateUsername = (alias) => {
  return Api({
    method: "POST",
    url: "/users/setUsernameAlias",
    data: {
      alias,
    },
  });
};

export const updatePassword = (password_new) => {
  return Api({
    method: "POST",
    url: "/users/changePasswordAccount",
    data: {
      password_new,
    },
  });
};

export const getAlerts = () => {
  return Api({
    method: "GET",
    url: "/push/getOptions",
  });
};

export const updateAlerts = (attribute, value) => {
  return Api({
    method: "POST",
    url: "/push/setOption",
    data: {
      attribute,
      value,
    },
  });
};

export const getRegisteredDevices = () => {
  return Api({
    method: "GET",
    url: "/push/getDevicesByUser",
  });
};

export const deleteRegisteredDevice = (id) => {
  return Api({
    method: "POST",
    url: "/push/deleteRegisteredDevice",
    data: {
      id,
    },
  });
};

export const activateDezactivateDevice = (id, status) => {
  return Api({
    method: "POST",
    url: "/push/activateDezactivateDevice",
    data: {
      id,
      status,
    },
  });
};

export const is2FAEnabled = () =>
  Api({
    method: "GET",
    url: "/users/is2FAEnabled",
  });

export const toggle2FA = (isActive, type = null) =>
  Api({
    method: "POST",
    url: "/users/toggle2FA",
    data: {
      isActive,
      type,
    },
  });

export const resetPassword = (input, uuidDevice) =>
  Api({
    method: "POST",
    url: "/users/resetPassword",
    data: {
      input,
      uuidDevice,
    },
  });

export const sendMessage = (data_post) => {
  return Api({
    method: "POST",
    url: "/site/sendMessage",
    data: data_post,
  });
};
