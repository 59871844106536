import React from "react";
import Modal from "react-modal";
import * as AuthService from "../../services/AuthService";
import { useDispatch } from "react-redux";
import { setLogin } from "../Global/actions";

import * as Helpers from "../../helpers";
import { prepareyAndPayForGuarnator } from "../../services/PayService";
import EditPay from "./EditPay";

const PayForThisGuarantor = ({ guarantor }) => {
  const [openModal, setOpenModal] = React.useState(false);
  const [total, setTotal] = React.useState(guarantor.suma_de_plata || 0);
  const [message, setMessage] = React.useState("");

  const dispatch = useDispatch();

  const toggleModal = () => {
    setOpenModal((prev) => !prev);
  };

  const payBtn = async () => {
    if (total < 10) {
      return setMessage("Suma minima este 10 RON");
    }
    // @TODO
    const { token, expireIn, user } = await AuthService.checkToken(true);
    dispatch(setLogin({ token, expireIn, user }));
    await Helpers.setConfigFromStorage({ token, expireIn, user });

    const payments = [
      {
        name: guarantor.tip_imprumut,
        payload: {
          amount: parseFloat(total),
          uniq: guarantor.cod_unic,
          currency: "RON",
        },
      },
    ];

    return prepareyAndPayForGuarnator({
      payments,
      setMessage,
      user_id: user.id,
    });
  };

  return (
    <>
      <button
        type="button"
        className={"px-4 btn color--black btn btn--sm type--uppercase"}
        onClick={toggleModal}
      >
        Plateste pentru {guarantor.numele_persoana_girata}
      </button>
      <Modal
        isOpen={openModal}
        onRequestClose={toggleModal}
        style={customStyles}
      >
        <h2>Plateste pentru {guarantor.numele_persoana_girata}</h2>
        <div className="alert bg--primary">
          <div className="alert__body">
            <span>
              In cazul in care efectuati plata cu un card de credit <br />
              consultati inainte banca emitenta daca apar costuri <br />
              suplimentare!
            </span>
          </div>
        </div>
        {!!message && (
          <div className="alert bg--primary">
            <div className="alert__body">
              <span>{message}</span>
            </div>
            <div className="alert__close">×</div>
          </div>
        )}

        <div className="text-right">
          <p className="lead">
            Suma totala de plata: <br />
            <EditPay oldValue={total} setNewValue={setTotal} />
          </p>
        </div>
        <hr />
        <div className="text-right">
          <button
            type="button"
            className={"my-2 px-4 btn color--black btn btn--sm type--uppercase"}
            onClick={toggleModal}
          >
            Inchide
          </button>
          <button
            disabled={!total}
            type="button"
            className={
              "my-2 px-4 btn color--black btn btn--sm type--uppercase btn--primary"
            }
            onClick={payBtn}
          >
            Plateste
          </button>
        </div>
      </Modal>
    </>
  );
};

export default PayForThisGuarantor;

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    padding: "30px",
    transform: "translate(-50%, -50%)",
  },
};
