import React from "react";
import InputCode from "./InputCode";
import Wallpaper from "./Wallpaper";
import * as AuthService from "../../services/AuthService";
import moment from "moment";
import { REDIRECT_TO_LOGIN_INPUT } from ".";
import { Redirect } from "react-router";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

const CodeForgetPassword = () => {
  const [message, setMessage] = React.useState("");
  const [redirect, setRedirect] = React.useState(null);

  React.useEffect(() => {
    checkCodeIfIsValid();
  }, []);

  const checkCodeIfIsValid = () => {
    const expireIn = AuthService.getExpirePasswordCode();

    if (expireIn && !AuthService.checkHasExpired(expireIn)) {
      setMessage(
        `Codul trimis va expira la ora: ${moment
          .unix(expireIn)
          .format("HH:mm:ss")}`
      );
    }
  };

  const setInput = async (code) => {
    const noSpaceInCode = code.replace(/\s/g, "");
    try {
      const { message } = await AuthService.codeTypedForChangePassword({
        code: noSpaceInCode,
      });
      setTimeout(() => {
        setRedirect(REDIRECT_TO_LOGIN_INPUT);
      }, 500);
      toast.success(message);
    } catch (err) {
      toast.error(err?.message || err);
    }
    return Promise.resolve(code);
  };

  if (redirect === REDIRECT_TO_LOGIN_INPUT) {
    return <Redirect to="/" />;
  }

  return (
    <Wallpaper>
      <InputCode setInput={setInput} message={message} />
      <div className="pt-2">
        <Link to="/login">Inapoi la conectare</Link>
      </div>
    </Wallpaper>
  );
};

export default CodeForgetPassword;
