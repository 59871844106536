import React from "react";
import Modal from "react-modal";
import * as Helpers from "../../helpers";
import EditWhatIWillPay from "./editWhatIWillPay";
import { getLimitEuPlatesc, prepareAndPay } from "../../services/PayService";
import * as AuthService from "../../services/AuthService";
import { useDispatch } from "react-redux";
import { setLogin } from "../Global/actions";

const Payment = ({ list }) => {
  const [openModal, setOpenModal] = React.useState(false);
  const [listWhatWillPay, setListWhatWillPay] = React.useState([]);
  const [pay, setPay] = React.useState([]);
  const [message, setMessage] = React.useState("");
  const [limitMaxPaymentEuPlatesc, setLimitMaxPaymentEuPlatesc] =
    React.useState(0);

  const dispatch = useDispatch();

  React.useEffect(() => {
    const prepare = [];

    const listFunds = list?.funds || [];
    for (let fund of listFunds) {
      prepare.push({
        numar_fond: fund.numar_fond,
        nume_tip: fund.nume_tip,
        suma: (parseFloat(fund.depunere) || 50).toPrecision(2),
        edit: false,
        sumaNoua: 0,
      });
    }

    const listLoans = list?.loans || [];
    for (let loan of listLoans) {
      prepare.push({
        numar_fond: loan.numar_fond,
        nume_tip: loan.nume_fond,
        suma: parseFloat(
          parseFloat(loan.dobanda_la_zi) + parseFloat(loan.rata_lunara_imprumut)
        ).toFixed(2),
        edit: false,
        sumaNoua: 0,
      });
    }

    const listExecutors = list?.executors || [];
    for (let executor of listExecutors) {
      prepare.push({
        numar_fond: executor.imprumut,
        nume_tip: executor.debit_fisa + " " + executor.nrcontract,
        suma: parseFloat(executor.sold).toFixed(2),
        edit: false,
        sumaNoua: 0,
      });
    }

    setListWhatWillPay(prepare);
  }, [list]);

  React.useEffect(() => {
    getLimitEuPlatesc().then((limit) => {
      setLimitMaxPaymentEuPlatesc(limit);
    });
  }, []);

  const toggleModal = () => {
    setOpenModal((prev) => !prev);
  };

  const setChecked = (p, s) => {
    const newPay = { ...pay };

    if (!newPay?.[p.numar_fond]) {
      newPay[p.numar_fond] = s;
    }

    newPay[p.numar_fond] = s;

    setPay(newPay);
    setMessage("");
  };

  const displayEditSum = (selected, edit) => {
    setListWhatWillPay((prev) =>
      prev.map((p) =>
        p.numar_fond === selected.numar_fond ? { ...p, edit } : p
      )
    );
  };

  const displayProducts = () => {
    return listWhatWillPay.map((p, i) => {
      const isSelected = pay?.[p.numar_fond];

      return (
        <div className="row" key={i}>
          <div className="col-md-12 col form--inline">
            <div className="input-checkbox">
              <input
                type="checkbox"
                id={p.numar_fond}
                defaultChecked={pay?.[p.numar_fond] || false}
                onChange={() => setChecked(p, !isSelected)}
              />

              <label htmlFor={p.numar_fond}></label>
            </div>

            {!p.edit && (
              <p className="lead inline-block py-4">
                {p.nume_tip}:{" "}
                <strong>
                  <a href="#edit" onClick={() => displayEditSum(p, true)}>
                    {Helpers.number_format_ro(p.suma)}
                  </a>
                </strong>{" "}
                RON
              </p>
            )}
            {p.edit && (
              <EditWhatIWillPay
                list={p}
                setListWhatWillPay={setListWhatWillPay}
              />
            )}
          </div>
        </div>
      );
    });
  };

  const payBtn = async () => {
    const pp = [];

    Object.keys(pay).forEach((key) => {
      const value = pay[key];

      const found = listWhatWillPay.filter(
        (list) => list.numar_fond === key && value
      );

      if (found.length > 0) pp.push(found[0]);
    });

    if (pp.length === 0) {
      setMessage("Ce doriti sa platiti?");
    }

    const payload = pp.map((each) => ({
      suma: each.suma,
      numar_fond: each.numar_fond,
      tip_plata: each.nume_tip,
    }));

    for (let each of payload) {
      if (!each["suma"] || parseFloat(each["suma"]) < 1) {
        setMessage(`Suma minima pentru ${each["tip_plata"]} este 1 leu.`);
        return false;
      }
    }

    // @TODO
    const { token, expireIn, user } = await AuthService.checkToken(true);
    dispatch(setLogin({ token, expireIn, user }));
    await Helpers.setConfigFromStorage({ token, expireIn, user });

    return prepareAndPay({
      payload,
      setMessage,
      limitMaxPaymentEuPlatesc,
      user_id: user.id,
    });
  };

  let total = 0;
  Object.keys(pay).forEach((key) => {
    const value = pay[key];

    const found = listWhatWillPay.filter(
      (list) => list.numar_fond === key && value
    );

    total += parseFloat(found[0]?.suma || 0);
  });

  return (
    <>
      <button
        type="button"
        className={"my-2 px-4 btn color--black btn btn--sm type--uppercase"}
        onClick={toggleModal}
      >
        Plateste online
      </button>
      <Modal
        isOpen={openModal}
        onRequestClose={toggleModal}
        style={customStyles}
      >
        <h2>Ce platiti?</h2>
        <div className="alert bg--primary">
          <div className="alert__body">
            <span>
              In cazul in care efectuati plata cu un card de credit <br />
              consultati inainte banca emitenta daca apar costuri <br />
              suplimentare!
            </span>
          </div>
        </div>

        {displayProducts()}
        <div className="text-right">
          <p className="lead">
            Suma totala de plata:{" "}
            <strong>{Helpers.number_format_ro(total)} RON</strong>
          </p>
        </div>
        <hr />
        {!!message && (
          <div className="alert bg--primary">
            <div className="alert__body">
              <span>{message}</span>
            </div>
            <div className="alert__close">×</div>
          </div>
        )}
        <div className="text-right">
          <button
            type="button"
            className={"my-2 px-4 btn color--black btn btn--sm type--uppercase"}
            onClick={toggleModal}
          >
            Inchide
          </button>
          <button
            disabled={!total}
            type="button"
            className={
              "my-2 px-4 btn color--black btn btn--sm type--uppercase btn--primary"
            }
            onClick={payBtn}
          >
            Plateste
          </button>
        </div>
      </Modal>
    </>
  );
};

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    padding: "30px",
    transform: "translate(-50%, -50%)",
  },
};

export default Payment;
