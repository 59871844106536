import React from "react";
import { Route, Redirect } from "react-router-dom";
import { Bar } from "../components";

import { useSelector } from "react-redux";

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { isLogged, user } = useSelector((state) => state.global);

  return (
    <Route
      {...rest}
      render={(props) =>
        isLogged && user?.id ? (
          <>
            <Bar />
            <Component {...props} />
          </>
        ) : (
          <Redirect
            to={{ pathname: "/login", state: { from: props.location } }}
          />
        )
      }
    />
  );
};

export default PrivateRoute;
