import React from "react";
import { Loading, Sections } from "../../components";
import * as UserService from "../../services/UserService";
import Modal from "react-modal";
import { toast } from "react-toastify";

const Activate2Fa = () => {
  const [data, setData] = React.useState({});
  const [loading, setLoading] = React.useState(true);
  const [openModal, setOpenModal] = React.useState(false);

  const [selected, setSelected] = React.useState(false);

  React.useEffect(() => {
    UserService.is2FAEnabled()
      .then(setData)
      .catch((e) => console.log(e))
      .finally(() => setLoading(false));
  }, []);

  const activateOrDezactivate = () => {
    if (data.is2FAEnabled) {
      setLoading(true);
      saveAs(false).then((response) => {
        setData((prev) => ({ ...prev, is2FAEnabled: response.isActive }));
        setLoading(false);
        toast.warn("Autentificare dubla este dezactivata.");
      });
    } else {
      setOpenModal(true);
      setLoading(true);
    }
  };

  const saveAs = (isActive, type = null) =>
    UserService.toggle2FA(isActive, type);

  const saveConfig = async () => {
    if (!selected) {
      toast.error("Este obligatoriu o selectie.");
      return false;
    }

    if (data.type2Fa === selected) {
      setLoading(false);
      setOpenModal(false);
      return false;
    }

    try {
      const response = await saveAs(!data.is2FAEnabled, selected);
      setData((prev) => ({ ...prev, is2FAEnabled: response.isActive }));
      toast.success("Autentificare dubla este activa.");
    } catch (err) {
      toast.error("Probleme cu servarul, reveniti mai tarziu.");
    } finally {
      setOpenModal(false);
      setLoading(false);
    }
  };

  const bodyRender = () => {
    if (loading) {
      return (
        <div className="my-5">
          <Loading />
        </div>
      );
    }

    return (
      <Sections.Container>
        <div className="boxed boxed--border  bg--secondary boxed--lg box-shadow text-center">
          <button
            onClick={activateOrDezactivate}
            className={`${
              data.is2FAEnabled ? "btn--primary-2" : "btn--primary"
            } btn px-5 `}
          >
            <span className="btn__text">
              {data.is2FAEnabled ? "Dezactiveaza" : "Activeaza"}
            </span>
          </button>
        </div>
      </Sections.Container>
    );
  };

  return (
    <>
      <Sections.Title
        title="Autentificare dubla"
        subtitle="Acest tip de verificare ofera contului dumneavoastra un nivel suplimentar de securitate, in special in cazul unui furt al parolei."
      />
      {bodyRender()}

      <Modal
        isOpen={openModal}
        beforeClose={() => setOpenModal((prev) => !prev)}
        onRequestClose={() => {
          setOpenModal(false);
          setLoading(false);
        }}
        style={customStyles}
      >
        <div className=" text-center">
          <h2 className="h2">
            Alegeti modalitatea suplimentara pentru verificare in doi pasi.
          </h2>

          <div className="row my-5">
            {data?.hasEmail && (
              <div
                className="col-md-6 col"
                onClick={() => setSelected("email")}
              >
                <div className="pricing pricing-3">
                  <div
                    className={`pricing__head ${
                      selected && selected === "email"
                        ? "bg--primary"
                        : "bg--secondary"
                    } boxed`}
                  >
                    <h5>Adresa de mail</h5>
                    <span className="h1">
                      <span className="pricing__dollar">{data.hasEmail}</span>
                    </span>
                  </div>
                </div>
              </div>
            )}
            {data?.hasPhone && (
              <div
                className="col-md-6 col"
                onClick={() => setSelected("phone")}
              >
                <div className="pricing pricing-3">
                  <div
                    className={`pricing__head ${
                      selected && selected === "phone"
                        ? "bg--primary"
                        : "bg--secondary"
                    } boxed`}
                  >
                    <h5>Numar de telefon</h5>
                    <span className="h1">
                      <span className="pricing__dollar">{data.hasPhone}</span>
                    </span>
                  </div>
                </div>
              </div>
            )}
          </div>

          <div>
            <button
              onClick={() => {
                setOpenModal(false);
                setLoading(false);
              }}
              className="btn px-5"
            >
              Renunta
            </button>
            <button onClick={saveConfig} className="btn--primary btn px-5">
              Salveaza
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    textAlign: "center",
  },
};

export default Activate2Fa;
