import React, { useState } from "react";

import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { setLogin } from "../Global/actions";
import * as AuthService from "../../services/AuthService";
import { PulseLoader } from "react-spinners";
import { toast } from "react-toastify";
import * as Helpers from "../../helpers";
import { Logo } from "../../components";
import Wallpaper from "./Wallpaper";

export const REDIRECT_TO_CODE = "code-two-fa";
export const REDIRECT_TO_DASHBOARD = "dashboard";
export const REDIRECT_TO_LOGIN_INPUT = "login";
export const REDIRECT_TO_FORGOT_PASSWORD_CODE = "code-forgot-password";

function Login() {
  const [redirect, setRedirect] = useState(null);

  const [credentials, setCredentials] = useState({
    username: "",
    password: "",
  });
  const [isLoading, setIsLoading] = useState(false);

  const [error, setError] = useState({
    username: "",
    password: "",
    hasError: false,
  });
  let history = useHistory();
  const dispatch = useDispatch();

  React.useEffect(() => {
    checkAlreadyHasCodeToInput();
  }, []);

  React.useEffect(() => {
    if (redirect) {
      switch (redirect) {
        case REDIRECT_TO_DASHBOARD:
          history.push("/");
          break;

        case REDIRECT_TO_CODE:
          history.push("code-two-fa");
          break;

        default:
          break;
      }
    }
  }, [redirect, history]);

  const checkAlreadyHasCodeToInput = async () => {
    const checking = await AuthService.getTwoFaToken();

    if (checking?.token) {
      setRedirect(REDIRECT_TO_CODE);
    }
  };

  const checkCredentials = (e) => {
    e.preventDefault();
    setIsLoading(true);

    // clean all errors!
    setError({
      hasError: false,
      username: "",
      password: "",
    });

    if (!credentials.username) {
      setError({
        ...error,
        hasError: true,
        username: "Utilizatorul sau fisa este obligatoriu!",
      });

      setIsLoading(false);

      toast.error("Utilizatorul sau fisa este obligatoriu!");

      return false;
    }

    if (!credentials.password) {
      setError({
        ...error,
        hasError: true,
        password: "Parola este obligatorie!",
      });
      setIsLoading(false);
      toast.error("Parola este obligatorie!");
      return false;
    }

    return loginIn();
  };

  const loginIn = async () => {
    try {
      const uid_device = await Helpers.getUniqueCode();

      const response = await AuthService.checkRemoteCredentials({
        credentials,
        uid_device,
      });

      const {
        token,
        user = null,
        twofa = null,
        expireIn = null,
        hasTwoFa = false,
      } = response;

      if (twofa && expireIn) {
        await AuthService.setTwoFAToken(token, expireIn);
        setRedirect(REDIRECT_TO_CODE);
        return false;
      }

      dispatch(setLogin({ token, expireIn, user, hasTwoFa }));

      await Helpers.setConfigFromStorage({ token, expireIn, user, hasTwoFa });

      setRedirect(REDIRECT_TO_DASHBOARD);
    } catch (err) {
      setIsLoading(false);
      setCredentials({
        ...credentials,
        password: "",
      });
      toast.error(
        typeof err === "string" ? err : err.message || "Unknown error!"
      );
    }
  };

  return (
    <Wallpaper>
      <div className="row">
        <div className="col-md-6 col-lg-4">
          <div className="boxed boxed--border boxed--lg text-center">
            <Logo />
            <p className="lead mt-4">
              Bine ati revenit, conectati-va cu datele de autentificare ale
              contului dvs.
            </p>
            <form
              onSubmit={checkCredentials}
              className="mt-2 pt-0"
              noValidate
              autoComplete="off"
            >
              <div className="row mt-1">
                <div className="col-md-12 ">
                  <input
                    type="text"
                    style={
                      error.hasError && error.username
                        ? { borderColor: "#c0392b" }
                        : {}
                    }
                    placeholder="Utilizator ( Fisa )"
                    value={credentials.username}
                    onChange={(e) => {
                      setCredentials({
                        ...credentials,
                        username: e.target.value,
                      });

                      setError({ ...error, username: "" });
                    }}
                  />
                </div>
                <div className="col-md-12">
                  <input
                    type="password"
                    placeholder="Parola"
                    value={credentials.password}
                    style={
                      error.hasError && error.password
                        ? { borderColor: "#c0392b" }
                        : {}
                    }
                    onChange={(e) => {
                      setCredentials({
                        ...credentials,
                        password: e.target.value,
                      });

                      setError({ ...error, password: "" });
                    }}
                  />
                </div>
                <div className="col-md-12 mt-2">
                  {isLoading ? (
                    <PulseLoader color="#4a90e2" />
                  ) : (
                    <button
                      className="btn btn--primary type--uppercase"
                      type="submit"
                    >
                      Conectare
                    </button>
                  )}
                </div>
              </div>
            </form>
          </div>
          <Link to="/lost">Am uitat parola</Link>
        </div>
      </div>
    </Wallpaper>
  );
}

export default Login;
