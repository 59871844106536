import React from "react";
import * as Helpers from "../../helpers";

const EditPay = ({ oldValue, setNewValue }) => {
  const [edit, setEdit] = React.useState(false);
  const [v, setV] = React.useState(oldValue);
  const change = (e) => {
    let newValue = e.target.value.replace(",", ".");
    var regex = /^[0-9.]+$/;
    if (!newValue.match(regex)) {
      alert("Introduceti doar numeric");
      return false;
    }
    setV(newValue);
  };

  const onClick = () => setEdit((prev) => !prev);
  const save = () => {
    setEdit(false);
    setNewValue(v);
  };

  return (
    <div>
      <strong>{Helpers.number_format_ro(oldValue)} RON</strong>
      <br />
      {edit ? (
        <div className="inline-block">
          <input
            onKeyDown={({ key }) => (key === "Enter" ? save() : null)}
            type="text"
            name="new-value"
            value={v}
            className="col-md-6 col text-right"
            placeholder="RON"
            onChange={change}
          />
          <button
            type="button"
            className="my-2 ml-4 px-4 btn color--black btn btn--sm type--uppercase"
            onClick={save}
          >
            Salveaza
          </button>
        </div>
      ) : (
        <small
          onClick={onClick}
          className="type--fine-print"
          style={{
            fontSize: 12,
            cursor: "pointer",
            textDecoration: "underline",
          }}
        >
          Alta suma?
        </small>
      )}
    </div>
  );
  // return (
  //   <div className="inline-block">
  //     <p className="lead inline-block"> {list.nume_tip}: &nbsp;&nbsp;&nbsp;</p>
  //     <input
  //       onKeyDown={({ key }) => (key === "Enter" ? save() : null)}
  //       type="text"
  //       name={list.nume_tip}
  //       value={list.sumaNoua || list.suma}
  //       className="col-md-2 col"
  //       placeholder="RON"
  //       onChange={change}
  //     />
  //     <button
  //       type="button"
  //       className={"my-2 px-4 btn color--black btn btn--sm type--uppercase"}
  //       onClick={save}
  //     >
  //       Salveaza
  //     </button>
  //   </div>
  // );
};

export default EditPay;
