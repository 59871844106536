import React, { useState, useEffect } from "react";
import Guarantor from "./guarantor";
import * as FinancialService from "../../services/FinancialService";
import _ from "lodash";
import { Loading, Container } from "../../components";
import { number_format_ro } from "../../helpers";

const Guarantors = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [guarantors, setGuarantors] = useState([]);

  useEffect(() => {
    setIsLoading(true);
    FinancialService.getGuarantors()
      .then((response) => {
        const { data } = response;
        const sort_detaliat = _.sortBy(data, "numele_persoana_girata");
        setGuarantors(sort_detaliat);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log("err", err);
      });
  }, []);

  const displayHeader = () => {
    const total = number_format_ro(
      guarantors.reduce(
        (previousValue, currentValue) =>
          parseFloat(currentValue.sold_ramas) +
          parseFloat(currentValue.dobanda_la_zi) +
          parseFloat(previousValue),
        0
      )
    );

    return (
      <div className="row px-4">
        <div className="col col-md-6 text-center-xs">
          <p>
            {" "}
            Numar imprumuturi girate: <strong>{guarantors.length}</strong>
          </p>
        </div>
        <div className="col col-md-6 text-right text-center-xs">
          <p>
            Suma totala: <strong>{total} RON</strong>
          </p>
        </div>
      </div>
    );
  };
  const displayData = () => {
    return guarantors.map((e, i) => <Guarantor guarantor={e} key={i} />);
  };

  if (isLoading) {
    return <Loading />;
  }

  const renderBody = () => {
    if (guarantors.length === 0) {
      return (
        <div className="alert bg--primary my-2 mx-2">
          <div className="alert__body ">
            <span>Nu sunteti girant in nici un contract de imprumut.</span>
          </div>
        </div>
      );
    }
    return (
      <div>
        {displayHeader()}
        <hr />
        {displayData()}
      </div>
    );
  };
  return <Container>{renderBody()}</Container>;
};

export default Guarantors;
