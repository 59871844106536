import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Routers from "../../routers";

import * as Helpers from "../../helpers";
import { setLogin, logoutSuccess } from "../Global/actions";
import { Loading } from "../../components";
import { ToastContainer, toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

const App = () => {
  const [loading, setLoading] = React.useState(true);
  const dispatch = useDispatch();
  const { isLogged, expireIn } = useSelector((state) => state.global);

  React.useEffect(() => {
    boostrap();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const boostrap = async () => {
    if (isLogged === false) {
      // check if exist in localstorage?
      const config = await Helpers.getConfigFromStorage();
      if (process.env.NODE_ENV !== "production") {
        console.log("App.boostrap", config);
      }
      if (config?.token && config?.user) {
        dispatch(setLogin(config));
      }
    }
    setLoading(false);
  };

  React.useEffect(() => {
    let timeOut = undefined;
    if (expireIn) {
      timeOut = setInterval(() => {
        const now = parseInt(Date.now() / 1000) || 0;
        if (expireIn !== "" && now > expireIn) {
          setTimeout(() => {
            dispatch(logoutSuccess());
          }, 500);
          Helpers.removeConfigFromStorage("", true);
          clearTimeout(timeOut);
        }
      }, 5000);
    }
    return () => timeOut;
  }, [expireIn, dispatch]);

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <Routers />
      <ToastContainer autoClose={10000} position={toast.POSITION.TOP_RIGHT} />
    </>
  );
};

export default App;
