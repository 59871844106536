import React, { useState } from "react";
import { toast } from "react-toastify";
import { COLOR_DANGER } from "../constants";
import Modal from "react-modal";
import * as UserService from "../services/UserService";
import * as Utils from "../helpers";
Modal.setAppElement("#root");

const Report = () => {
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [description, setDescription] = useState("");
  const [hasError, setHasError] = useState(false);

  const toggleModal = () => setIsOpen((prev) => !prev);

  const sendReport = (e) => {
    e.preventDefault();

    if (description === "") {
      toast.error("Descrierea problemei este obligatorie.");
      setHasError(true);
      return false;
    }

    if (description.length < 10) {
      toast.error(
        "Descrierea problemei este prea scurta, folositi cel putin 10 caractere."
      );
      setHasError(true);
      return false;
    }

    UserService.sendMessage({
      subiect: "Parerea unui utilizator dupa aplicatia mobila",
      text: description,
      browser: Utils.getBrowser(),
    })
      .then(({ message = "Mesajul a fost trimis." }) => {
        setDescription("");
        toast.success(message);
      })
      .catch((err) => toast.error(err?.message || err)); // send problem on internet web
  };
  return (
    <>
      <a href="#raportare-problema" onClick={toggleModal}>
        Raportare problema
      </a>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={toggleModal}
        style={customStyles}
      >
        <h3>Raportare problema</h3>
        <form onSubmit={sendReport}>
          <div>
            <label>Descriere:</label>
            <textarea
              style={hasError ? { borderColor: COLOR_DANGER } : {}}
              name="text"
              placeholder="Descriere problema"
              rows="4"
              value={description}
              onChange={(e) => {
                setHasError(false);
                setDescription(e.target.value);
              }}
            ></textarea>
            <button type="submit" className="btn btn--primary mt-4">
              Trimite
            </button>

            <div className="text-center">
              <button
                type="text"
                className={
                  "my-3 px-4 btn color--black btn type--uppercase text-center"
                }
                onClick={toggleModal}
              >
                Inchide
              </button>
            </div>
          </div>
        </form>
      </Modal>
    </>
  );
};

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    padding: "30px",
    transform: "translate(-50%, -50%)",
  },
};

export default Report;
