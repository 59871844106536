import React, { useState, useEffect } from "react";
import Filters from "./Filters";
import Transaction from "./transaction";
import moment from "moment";
import { Loading, Container } from "../../components";

import * as FinancialService from "../../services/FinancialService";

const defaultFilter = {
  data_start: moment().subtract("7", "days").format("YYYY-MM-DD"),
  data_end: moment().format("YYYY-MM-DD"),
  searchingText: "",
};

const Transactions = () => {
  const [filter, setFilter] = useState(defaultFilter);

  const [isLoading, setIsLoading] = useState(true);
  const [rulaje, setRulaj] = useState([]);

  useEffect(() => {
    setIsLoading(true);

    FinancialService.getRulaj(filter)
      .then((response) => {
        setRulaj(response.data || []);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  }, [filter]);

  const changeFilter = (newFilter) => {
    setFilter({
      ...filter,
      ...newFilter,
    });
  };

  // Make here with
  return (
    <Container>
      <div className="row" style={{ minHeight: 500 }}>
        <div className="col-md-12">
          <Filters changeFilter={changeFilter} />
          <hr />
          {isLoading ? (
            <Loading />
          ) : (
            <>
              {rulaje.length === 0 ? (
                <div className="alert bg--primary my-2 mx-2">
                  <div className="alert__body ">
                    <span>
                      Nu aveti nici o tranzactie in perioada selectata.
                    </span>
                  </div>
                </div>
              ) : (
                <table className="border--round table--alternate-row">
                  <tbody>
                    {rulaje.map((rulaj) =>
                      rulaj.rulaje.map((e) => (
                        <Transaction rulaj={e} key={e.id} />
                      ))
                    )}
                  </tbody>
                </table>
              )}
            </>
          )}
        </div>
      </div>
    </Container>
  );
};

export default Transactions;
