import React from "react";
import PropTypes from "prop-types";
import { number_format_ro, data_format_ro } from "../../helpers";
const Funds = (props) => {
  const { fund } = props;
  return (
    <tr>
      <td valign="top" colSpan="2">
        {fund.nume_tip}
      </td>
      <td valign="top">
        {number_format_ro(fund.sold_fond)} {fund.tip_moneda}
      </td>
      <td valign="top">
        {number_format_ro(fund.sold_fond_dobanda)} {fund.tip_moneda}
      </td>
      <td valign="top" className="hidden-xs">
        {number_format_ro(fund.depunere)} {fund.tip_moneda}
      </td>
      <td valign="top" className="hidden-xs">
        {fund.data_ultima_operatie_depunere
          ? data_format_ro(fund.data_ultima_operatie_depunere)
          : " - "}
      </td>
    </tr>
  );
};

Funds.propTypes = {
  fund: PropTypes.object.isRequired,
};

export default Funds;
