import Api from "./Api";

export const getAll = () =>
  Api({
    method: "GET",
    url: "/financial/getAll",
  });

// @TODO - remove this
export const getFunds = () => {
  return Api({
    method: "GET",
    url: "/financial/getFunds",
  });
};
// @TODO - remove this
export const getLoans = () => {
  return Api({
    method: "GET",
    url: "/financial/getLoans",
  });
};

export const getRulaj = ({ data_start, data_end, searchingText = "" }) => {
  return Api({
    method: "GET",
    url: "/rulaj/get",
    params: {
      data_start,
      data_end,
      searchingText,
    },
  });
};

export const getGuarantors = () => {
  return Api({
    method: "GET",
    url: "/giranti/getByUser",
    // params: {
    //   search
    // }
  });
};
