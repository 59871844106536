import React from "react";

export const Title = ({ title = "", subtitle = "" }) => {
  return (
    <>
      <section className="pt-5 switchable switchable--switch bg--secondary">
        <div className="container">
          <div className="row justify-content-center text-center">
            <div className="col-md-10 col-lg-8">
              {!!title && <h2>{title}</h2>}
              {!!subtitle && <p className="lead">{subtitle}</p>}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export const Container = ({ children, isSecondary }) => {
  return (
    <>
      <section
        className={`pt-5 switchable switchable--switch ${
          isSecondary && "bg--secondary"
        }`}
      >
        <div className="container">{children}</div>
      </section>
    </>
  );
};
