import React from "react";
import DatePicker from "react-datepicker";
import PropTypes from "prop-types";

import "react-datepicker/dist/react-datepicker.css";
import "./react-datapicker-custom.css";

const DataRangerPicker = ({ setRangeInterval }) => {
  const [startDate, setStartDate] = React.useState(
    new Date().setMonth(new Date().getMonth() - 1)
  );
  const [endDate, setEndDate] = React.useState(new Date());

  return (
    <div className="row">
      <div className="col">
        <DatePicker
          selected={startDate}
          startDate={startDate}
          selectsStart
          endDate={endDate}
          onChange={(change) => {
            setStartDate(change);
            setRangeInterval({
              startDate: change,
              endDate,
            });
          }}
          dateFormat="dd.MM.yyyy"
          minDate={new Date().setMonth(new Date().getMonth() - 12)}
          //locale="en-RO"
          style={{ width: "100px" }}
          //isClearable={true}
          placeholderText="De la data"
        />
      </div>
      <div className="col">
        <DatePicker
          selected={endDate}
          selectsEnd
          onChange={(change) => {
            setEndDate(change);

            setRangeInterval({
              startDate,
              endDate: change,
            });
          }}
          maxDate={new Date()}
          startDate={startDate}
          endDate={endDate}
          dateFormat="dd.MM.yyyy"
          //locale="en-RO"
          style={{ width: "100px" }}
          //isClearable={true}
          placeholderText="Pana la data de"
        />
      </div>
    </div>
  );
};

DataRangerPicker.propTypes = {
  setRangeInterval: PropTypes.func.isRequired,
};
export default DataRangerPicker;
