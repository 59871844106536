import React, { useState, useEffect } from "react";
import * as UserService from "../../services/UserService";
import { Sections, Loading } from "../../components";
import { toast } from "react-toastify";
import { COLOR_DANGER } from "../../constants";

const ChangeAlias = () => {
  const [alias, setAlias] = useState({
    alias: "",
    aliasOld: "",
    error: "",
  });
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingProceess, setIsLoadingProcess] = useState(false);

  useEffect(() => {
    UserService.getUsername()
      .then((response) => {
        const { alias = "" } = response;
        setAlias({
          alias,
          error: "",
        });
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  }, []);

  const submitChangeUsername = (e) => {
    e.preventDefault();
    setIsLoadingProcess(true);
    UserService.updateUsername(alias.alias)
      .then(() => {
        toast.success("Operatiune efectuata cu succes.");
        setIsLoadingProcess(false);
      })
      .catch((err) => {
        setIsLoadingProcess(false);
        setAlias({
          ...alias,
          error: err.message || err || "Unknown",
        });
        toast.error(err.message || err || "Unknown");
      });
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      <Sections.Title
        title="Schimbare utilizator"
        subtitle="Aceasta optiune este disponibila pentru a-ti permite o autentificare mai facila, in loc de numarul de fisa sa iti alegi un nume , un alias mai usor de retinut."
      />
      <Sections.Container>
        <div className="row">
          <div className="col-md-12">
            <h6 className="type--uppercase mb-0 pb-0">Schimbare utilizator</h6>
            <form className="row" onSubmit={submitChangeUsername}>
              <div className="col-md-8 ml-3">
                <input
                  type="text"
                  name="username"
                  placeholder={alias.alias}
                  value={alias.alias}
                  style={
                    alias.error !== "" ? { borderColor: COLOR_DANGER } : {}
                  }
                  onChange={(e) =>
                    setAlias({ alias: e.target.value, error: "" })
                  }
                />
              </div>
              <div className="col-md-3">
                {isLoadingProceess ? (
                  <Loading />
                ) : (
                  <button
                    type="submit"
                    className="btn btn--primary type--uppercase mt-1"
                  >
                    Schimba
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
      </Sections.Container>
    </>
  );
};

export default ChangeAlias;
