import React, { useState, useEffect } from "react";
import * as UserService from "../../services/UserService";
import { Sections, Loading } from "../../components";
import _ from "lodash";
import Editable from "./editable";

const PersonalData = () => {
  const [userProfile, setUserProfile] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    UserService.getProfile()
      .then((data) => {
        setUserProfile(data);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  }, []);

  const displayData = () => {
    if (_.isEmpty(userProfile)) {
      return (
        <div className="col-md-12">
          <div className="alert bg--error my-2 mx-2">
            <div className="alert__body ">
              <span>Lipsa date. Va rugam contactati-ne!</span>
            </div>
          </div>
        </div>
      );
    }

    return (
      <>
        <div className="col-md-6">
          <div className="text-block">
            <h5>Nume</h5>
            <p>{userProfile.name || " - "}</p>
          </div>
          <div className="text-block mt-0">
            <h5>Adresa</h5>
            <p>{userProfile.address || " - "}</p>
          </div>
        </div>
        <div className="col-md-6">
          <div className="text-block mt-0">
            <h5>Localitate</h5>
            <p>{userProfile.locality || " - "}</p>
          </div>
          <div className="text-block mt-0">
            <h5>Judet / Sector</h5>
            <p>{userProfile.county || " - "}</p>
          </div>
        </div>
      </>
    );
  };

  if (isLoading) return <Loading />;

  return (
    <>
      <Sections.Title
        title="Date personale"
        subtitle="Orice modificare asupra datelor personale se face telefonic sau la
    sediul CAR METROPOLITAN IFN. Pentru mai multe informatii,
    asteptam sa ne contactati."
      />
      <Sections.Container>
        <div className="row text-center">{displayData()}</div>

        <h1 className="my-5 h2 text-center">Date de contact</h1>
        <Editable profile={userProfile} />
      </Sections.Container>
    </>
  );
};

export default PersonalData;
