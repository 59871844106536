import React, { useState } from "react";
import MaskedInput from "react-text-mask";
import { toast } from "react-toastify";
import { updateProfile } from "../../services/UserService";

const EDIT_PHONE = "phone";
const EDIT_EMAIL = "email";

const View = ({ variable, value, setEdit }) => (
  <p>
    {value || " - "}
    <small>
      {" "}
      <a href={`#edit-${variable}`} onClick={() => setEdit(variable)}>
        Edit
      </a>
    </small>
  </p>
);

const ButtonUpdate = ({ onPressed }) => (
  <button
    onClick={onPressed}
    className="mr-1 py-2 px-4 btn color--white mt-0 btn--primary-1 block"
    type="button"
  >
    Editeaza
  </button>
);

const Update = ({ variabile, value, setEdit }) => {
  const [newValue, setNewValue] = React.useState(value);

  const onPressed = () => {
    if (variabile === EDIT_PHONE) {
      if (newValue.length > 1 && newValue.length < 9) {
        toast.error("Numarul de telefon nu este corect.");
        return false;
      }
    }

    if (variabile === EDIT_EMAIL) {
      if (
        newValue &&
        !/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(newValue)
      ) {
        toast.error("Adresa de mail nu este corecta.");
        return false;
      }
    }

    updateProfile(variabile, newValue)
      .then(() => {
        toast.success(
          "Datele vor fi prelucrate si modificarile vor aparea pe fisa in cel mai scurt timp posibil. Multumim!",
          { duration: 1000 }
        );
      })
      .catch((err) => {
        toast.error(
          err.message ||
            err ||
            "Eroare la modificarea datelor, incercati mai tarziu.",
          {
            duration: 1000,
          }
        );
      })
      .finally(() => {
        setEdit("");
      });
  };

  if (variabile === EDIT_PHONE) {
    return (
      <div className="row">
        <div className="col-md-8">
          <MaskedInput
            value={newValue}
            style={{ paddingLeft: 3, paddingRight: 3 }}
            mask={[
              "(",
              /[0]/,
              /\d/,
              /\d/,
              /\d/,
              ")",
              " ",
              /\d/,
              /\d/,
              /\d/,
              " ",
              /\d/,
              /\d/,
              /\d/,
            ]}
            onChange={(e) => {
              setNewValue(e.target.value.replace(/[^0-9]/g, ""));
            }}
          />
        </div>
        <div className="col-md-3">
          <ButtonUpdate onPressed={onPressed} />
        </div>
      </div>
    );
  }

  if (variabile === EDIT_EMAIL) {
    return (
      <div className="row">
        <div className="col-md-8">
          <input
            onKeyPress={(e) => e.code.indexOf("Enter") > -1 && onPressed()}
            type="email"
            value={newValue}
            onChange={(e) => setNewValue(e.target.value)}
          />
        </div>
        <div className="col-md-3">
          <ButtonUpdate onPressed={onPressed} />
        </div>
      </div>
    );
  }

  return null;
};

const Editable = ({ profile }) => {
  const [edit, setEdit] = useState(null);

  return (
    <div className="col-md-12 text-center">
      <div className="text-block mt-0">
        <div className="row">
          <div className="col-md-6">
            <h5>Telefon</h5>
            {edit !== EDIT_PHONE && (
              <View
                variable={EDIT_PHONE}
                value={profile.phone}
                setEdit={setEdit}
              />
            )}
            {edit === EDIT_PHONE && (
              <div className="row">
                <div className="col-md-8 col">
                  <Update
                    variabile={EDIT_PHONE}
                    value={profile.phone}
                    setEdit={setEdit}
                  />
                </div>
              </div>
            )}
          </div>

          <div className="col-md-6">
            <h5>Adresa de mail</h5>
            {edit !== EDIT_EMAIL && (
              <View
                variable={EDIT_EMAIL}
                value={profile.email}
                setEdit={setEdit}
              />
            )}
            {edit === EDIT_EMAIL && (
              <div className="row">
                <div className="col-md-8 col">
                  <Update
                    variabile={EDIT_EMAIL}
                    value={profile.email}
                    setEdit={setEdit}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Editable;
