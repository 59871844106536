import React from "react";

import { Switch, Route, BrowserRouter } from "react-router-dom";

import Login from "../screens/Login";
import LostPassword from "../screens/Login/LostPassword";
import Code2FA from "../screens/Login/Code2FA";

import { NotFound } from "../components";
import PrivateRoute from "./PrivateRoute";

import TransactionScreen from "../screens/Transactions";
import GuarantorsScreen from "../screens/Guarantors";
import FinancialScreen from "../screens/Financial";

import ConfirmarePlataScreen from "../screens/ConfirmarePlata";
import ConfirmareSmartPayScreen from "../screens/ConfirmarePlata/Smartpay";
import DatePersonaleScreen from "../screens/PersonalData";
import ChangePasswordScreen from "../screens/ChangePassword";
import ChangeAliasScreen from "../screens/ChangeAlias";
import Activate2FaScreen from "../screens/Activate2FA";
import CodeResetPassword from "../screens/Login/CodeForgetPassword";

function Routers() {
  return (
    <BrowserRouter>
      <Switch>
        <PrivateRoute exact path="/" component={FinancialScreen} />

        <PrivateRoute path="/giranti" component={GuarantorsScreen} />
        <PrivateRoute path="/tranzactii" component={TransactionScreen} />
        <PrivateRoute path="/date-personale" component={DatePersonaleScreen} />
        <PrivateRoute
          path="/schimbare-parola"
          component={ChangePasswordScreen}
        />
        <PrivateRoute
          path="/schimbare-utilizator"
          component={ChangeAliasScreen}
        />
        <PrivateRoute
          path="/autentificare-dubla"
          component={Activate2FaScreen}
        />

        <Route path="/login" component={Login} />
        <Route path="/lost" component={LostPassword} />
        <Route path="/code-two-fa" component={Code2FA} />
        <Route path="/code-reset-password" component={CodeResetPassword} />

        <Route path="/confirmare-plata" component={ConfirmarePlataScreen} />
        <Route
          path="/confirmare-success-plata"
          component={ConfirmarePlataScreen}
        />
        <Route
          path="/confirmare-eroare-plata"
          component={() => <ConfirmarePlataScreen isAErrorWithPayment={true} />}
        />
        <Route
          path="/confirmare"
          component={() => <ConfirmareSmartPayScreen />}
        />

        {/* <UserProvider>
          <Route path="/testing" component={TestingScreen} />
        </UserProvider> */}
        <Route component={NotFound} />
      </Switch>
    </BrowserRouter>
  );
}

export default Routers;
