export const SET_LOGIN = "SET_LOGIN";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";

const initialState = {
  isLogged: false,
  token: "",
  expireIn: "",
  user: {},
  hasTwoFa: false,
};

const GlobalReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_LOGIN:
      const isLogged = !!action.payload.token;
      return {
        ...state,
        isLogged,
        token: action.payload.token,
        expireIn: parseInt(action.payload.expireIn),
        user: action.payload?.user || {},
        hasTwoFa: action.payload?.hasTwoFa || false,
      };
    case LOGOUT_SUCCESS:
      return { ...state, ...initialState };
    default:
      return state;
  }
};

export default GlobalReducer;
