import axios from "axios";
import moment from "moment";
import * as commonActions from "../screens/Global/actions";
import store from "../store";
import history from "../history";
import * as Helpers from "../helpers";
import { BASE_URL_API } from "../constants";

const Api = async (options) => {
  const tokenCookie = Helpers.getCookie("authToken");
  const config = await Helpers.getConfigFromStorage();

  const axiosOptions = {
    baseURL: BASE_URL_API,
    timeout: 29000,
    includeCredentials: true,
    withCredentials: true,
    headers: {
      "Time-Request": moment().valueOf(),
      "Cache-Control": "must-revalidate,no-cache,no-store",
      Token: `${tokenCookie || config?.token}`,
      Authorization: `Bearer ${tokenCookie || config?.token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };

  const client = axios.create(axiosOptions);

  const onSuccess = function (response) {
    // console.debug("Request Successful!", response);
    return response.data;
  };

  const onError = function (error) {
    if (error.response && error.response.status === 401) {
      store.dispatch(commonActions.logoutSuccess());
      Helpers.removeConfigFromStorage("", true);
      setTimeout(() => {
        history.replace("/login");
      }, 100);
      return error.response.data.message;
    } else {
      if (error?.response?.data?.message) {
        return Promise.reject(error.response.data.message);
      }

      return Promise.reject(
        error?.response?.message === "Network Error"
          ? "Probleme conectare cu server, incercati mai tarziu!"
          : (error.response && error.response.message) || "Eroare necunoscuta."
      );
    }
  };

  // client.interceptors.response.use((rsp) => {
  //   console.log("rsp", rsp.headers);

  //   return rsp;
  // });

  return client(options).then(onSuccess).catch(onError);
};

export default Api;
