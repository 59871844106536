import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { logoutSuccess } from "../screens/Global/actions";
import { Link } from "react-router-dom";
import Report from "../screens/Report";
import * as Helpers from "../helpers";
import { useHistory } from "react-router-dom";

const Bar = () => {
  const dispatch = useDispatch();
  let history = useHistory();

  const {
    isLogged,
    hasTwoFa,
    user: { name: nameDisplayOnBar },
  } = useSelector((state) => state.global);
  // const isLogged = useSelector((state) => state.global.isLogged);
  // const nameDisplayOnBar = useSelector((state) => state.global.user.name);

  const logout = (e) => {
    e.preventDefault();
    Helpers.removeConfigFromStorage("", true);
    setTimeout(() => {
      dispatch(logoutSuccess());
    }, 50);
  };

  React.useEffect(() => {
    if (!isLogged) {
      history.push("/login");
    }
  }, [isLogged, history]);

  return (
    <nav className="bar bar--sm bar-1 bg--primary-1" id="menu5">
      <div className="container">
        <div className="row my-0">
          <div className="col text-right-xs">
            <div className="bar__module">
              <Link to="/">
                <img
                  className="hidden-xs hidden-sm mt-1 mb-0"
                  alt="Casa de Ajutor Reciproc Metropolitan IFN"
                  src="https://membri.carmetropolitan.ro/img/logo-with-description.png"
                />
                <img
                  alt="Casa de Ajutor Reciproc Metropolitan IFN"
                  height={35}
                  className="hidden-md hidden-lg"
                  src="https://membri.carmetropolitan.ro/img/logo-short.png"
                />
              </Link>
            </div>
          </div>
          <div className="col-md-5 text-right-xs">
            <div className="bar__module">
              <ul className="menu-horizontal">
                <li className="dropdown">
                  <span className="dropdown__trigger" style={{ color: "#fff" }}>
                    <i className="stack-interface stack-bell"></i>
                    Contact
                  </span>
                  <div className="dropdown__container">
                    <div className="container">
                      <div className="row">
                        <div className="dropdown__content col-lg-3 col-md-5">
                          <ul className="menu-vertical">
                            <li>
                              <a href="tel:+40 21 336 35 85">
                                T: +40 21 336 35 85
                              </a>
                            </li>
                            <li>
                              <a href="mailto:office@carmetropolitan.ro">
                                E: office(@)carmetropolitan.ro
                              </a>
                            </li>
                            <li>
                              <a href="https://www.carmetropolitan.ro/pagina-de-contact">
                                W: Date de contact
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>

          <div className="col-md-5 col text-right">
            <div className="bar__module">
              <ul className="menu-horizontal">
                <li className="dropdown dropdown-active">
                  <span className="dropdown__trigger" style={{ color: "#fff" }}>
                    <i className="stack-interface stack-users"></i>
                    {nameDisplayOnBar}
                  </span>

                  <div className="dropdown__container  text-center-xs text-left">
                    <div className="container">
                      <div className="row">
                        <div className="dropdown__content col-lg-2 col-md-4">
                          <ul className="menu-vertical">
                            <li>
                              <Report />
                            </li>

                            <li className="dropdown">
                              <span
                                className="dropdown__trigger"
                                style={{ color: "#666666" }}
                              >
                                Setari
                              </span>
                              <div className="dropdown__container">
                                <div className="container">
                                  <div className="row">
                                    <div className="dropdown__content col-lg-2 col-md-4">
                                      <ul className="menu-vertical">
                                        {hasTwoFa && (
                                          <li>
                                            <Link to="/date-personale">
                                              Date personale
                                            </Link>
                                          </li>
                                        )}
                                        <li>
                                          <Link to="/schimbare-parola">
                                            Schimbare parola
                                          </Link>
                                        </li>
                                        <li>
                                          <Link to="/schimbare-utilizator">
                                            Schimbare utilizator
                                          </Link>
                                        </li>
                                        <li>
                                          <Link to="/autentificare-dubla">
                                            Autentificare dubla
                                          </Link>
                                        </li>
                                        {/* <li>
                                          <Link to="/alerte">Alerte</Link>
                                        </li>
                                        <li>
                                          <Link to="/dispozitive">
                                            Dispozitive
                                          </Link>
                                        </li> */}
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </li>

                            <li>
                              <a href="#logout" onClick={logout}>
                                Deconectare
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Bar;
