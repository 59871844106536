import moment from "moment";
import { EncryptStorage } from "encrypt-storage";
import md5 from "md5";

const KEY_CONFIG_STORAGE = "cfg";
export function data_format_ro(data) {
  return moment(data).format("DD.MM.YYYY");
}

export function number_format_ro(number) {
  return number_format(number, 2, ",", ".");
}

export function number_format(number, decimals, dec_point, thousands_sep) {
  var n = !isFinite(+number) ? 0 : +number,
    prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
    sep = typeof thousands_sep === "undefined" ? "," : thousands_sep,
    dec = typeof dec_point === "undefined" ? "." : dec_point,
    toFixedFix = function (n, prec) {
      // Fix for IE parseFloat(0.55).toFixed(0) = 0;
      var k = Math.pow(10, prec);
      return Math.round(n * k) / k;
    },
    s = (prec ? toFixedFix(n, prec) : Math.round(n)).toString().split(".");
  if (s[0].length > 3) {
    s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
  }
  if ((s[1] || "").length < prec) {
    s[1] = s[1] || "";
    s[1] += new Array(prec - s[1].length + 1).join("0");
  }
  return s.join(dec);
}

// 30 de zile default
export const getUniqueCode = async () => {
  return getUniqueOfThisBrowser();
};

export const getUniqueOfThisBrowser = () => {
  const res = [];
  res.push(navigator.userAgent);
  res.push(navigator.vendor);
  res.push(navigator.languages);
  res.push(navigator.hardwareConcurrency);
  res.push(Object.keys(navigator).length);
  res.push(JSON.stringify(getBrowser()));
  return md5(JSON.stringify(res));
};

export const getBrowser = () => {
  var ua = navigator.userAgent,
    tem,
    M =
      ua.match(
        /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i
      ) || [];
  if (/trident/i.test(M[1])) {
    tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
    return { name: "IE", version: tem[1] || "" };
  }
  if (M[1] === "Chrome") {
    tem = ua.match(/\bOPR|Edge\/(\d+)/);
    if (tem != null) {
      return { name: "Opera", version: tem[1] };
    }
  }
  M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, "-?"];
  if ((tem = ua.match(/version\/(\d+)/i)) != null) {
    M.splice(1, 1, tem[1]);
  }
  return {
    name: M[0],
    version: M[1],
  };
};

export const storage = async () => {
  const secretKey = await getUniqueCode();
  const strg = EncryptStorage(`${secretKey}-car-metropolitan-ifn`, {
    storageType: "localStorage",
    prefix: "@car",
  });

  // if we have an invalid

  try {
    strg.getItem(KEY_CONFIG_STORAGE);
  } catch (err) {
    strg.clear();
  }

  return strg;
};

export const setConfigFromStorage = async (arr) => {
  const str = await storage();
  const configOld = str.getItem(KEY_CONFIG_STORAGE) || {};
  const config = {};

  for (let idx in arr) {
    config[idx] = arr[idx];
  }

  str.setItem(KEY_CONFIG_STORAGE, { ...configOld, ...config });
  return true;
};

export const getConfigFromStorage = async () => {
  const str = await storage();
  let config = {};

  try {
    config = str.getItem(KEY_CONFIG_STORAGE);
  } catch (err) {}

  return config;
};

export const removeConfigFromStorage = async (key, all = false) => {
  const str = await storage();
  if (all) {
    str.clear();
    return true;
  }

  let config = await getConfigFromStorage();

  if (config?.[key]) {
    delete config[key];
    str.setItem(KEY_CONFIG_STORAGE, config);
  }

  return true;
};

export const getCookie = (cookieName = "authToken") => {
  const cookies = document?.cookie?.split(";") || [];
  for (const cookie of cookies) {
    const [name, value] = cookie.split("=");
    if (name.trim() === cookieName) {
      return value;
    }
  }

  return null;
};

export const getCookieFromHeaders = (headers, cookieName = "authToken") => {
  const cookies = headers?.[cookieName]?.split(";");
  for (const cookie of cookies) {
    const [name, value] = cookie.split("=");
    if (name.trim() === cookieName) {
      return value;
    }
  }

  return null;
};
