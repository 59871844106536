import React from "react";
import Fund from "./fund";
import { number_format_ro } from "../../helpers";

const Funds = ({ funds = [] }) => {
  const total = number_format_ro(
    funds.reduce(
      (previousValue, currentValue) =>
        parseFloat(currentValue.sold_fond) +
        // parseFloat(currentValue.sold_fond_dobanda) +
        parseFloat(previousValue),
      0
    )
  );

  const displayData = () => {
    if (funds.length === 0) {
      return (
        <div className="alert bg--error my-2 mx-2">
          <div className="alert__body ">
            <span>Lipsa date. Va rugam contactati-ne!</span>
          </div>
        </div>
      );
    }

    return (
      <table>
        <thead>
          <tr>
            <th colSpan="2">Denumire</th>
            <th>Sold</th>
            <th>Dobanda</th>
            <th className="hidden-xs">Depunere</th>
            <th className="hidden-xs">Modificare</th>
          </tr>
        </thead>
        <tbody>
          {funds.map((each) => (
            <Fund key={each.id} fund={each} />
          ))}
        </tbody>
      </table>
    );
  };

  return (
    <div className="cta cta-1 cta--horizontal boxed boxed--border my-0 py-0 px-0 mx-0">
      <div className="row ">
        <div className="col-lg-12 col-md-12 bg--primary">
          <div className="row">
            <div className="col">
              <h4 className="mb-1 py-2  ml-4 pl-0 text-left">Fonduri</h4>
            </div>
            <div className="text-right col">
              <h4 className="mb-1 py-2 mr-4">{total} RON</h4>
            </div>
          </div>
        </div>
      </div>
      {displayData()}
    </div>
  );
};

Funds.propTypes = {};

export default Funds;
