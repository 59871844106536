import React, { useState } from "react";
import { WALLPAPER_LINK } from "../../constants";
import { useSelector } from "react-redux";
const Wallpaper = ({ children }) => {
  const { isLogged } = useSelector((state) => state.global);
  const [wallpaper, setWallpaper] = useState("");
  const [alt, setAlt] = useState("");

  React.useEffect(() => {
    const doNotChacheImage = Math.floor(Math.random() * 1500);
    setWallpaper(`${WALLPAPER_LINK}${doNotChacheImage}`);
    setAlt(`Wallpaper-Loggin-${doNotChacheImage}`);
  }, [isLogged]);

  if (!wallpaper || !alt) return null;
  return (
    <div className="height-100 bg--secondary">
      <section className="cover cover-features space--lg height-20  text-center bg--secondary">
        <div className="background-image-holder">
          <img alt={alt} src={wallpaper} key={wallpaper} />
        </div>

        <div className="container pos-vertical-center">{children}</div>
      </section>
    </div>
  );
};

export default Wallpaper;
