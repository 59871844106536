import React, { useState } from "react";
import PropTypes from "prop-types";
import { number_format_ro, data_format_ro } from "../../helpers";
import { COLOR_DANGER } from "../../constants";
import moment from "moment";
import PayForThisGuarantor from "./PayForThisGuarantor";

const Guarantor = (props) => {
  const { guarantor } = props;
  const [selected, setSelect] = useState(false);

  let deadlineExceeded = false;
  let hasDebt = false;

  const { dataf } = guarantor;
  const debtRemote = parseFloat(guarantor.sume_restante) || 0;

  if (dataf && moment(dataf).diff(moment(), "days") < 0) {
    deadlineExceeded = true;
  }

  if (debtRemote > 0) {
    hasDebt = true;
  }

  return (
    <div className="cta cta-1 cta--horizontal boxed boxed--border mt-0 py-0 px-0 mx-0 mb-1">
      <div className="row">
        <div
          className="col-lg-12 col-sm-12 col-md-12 bg--primary"
          style={
            parseInt(guarantor.restanta) > 0
              ? { backgroundColor: COLOR_DANGER }
              : {}
          }
        >
          <a
            href="#pressed"
            onClick={() => setSelect(!selected)}
            className="block"
          >
            <div className="row">
              <div className="text-left text-center-xs col d-flex flex-row justify-content-start mt-2">
                <div className="pl-4 pb-3">
                  <h4>{guarantor.numele_persoana_girata}</h4>
                </div>
              </div>

              <div className="text-right col d-flex flex-row justify-content-end mt-2">
                <div>
                  <h4>
                    {number_format_ro(
                      parseFloat(guarantor.sold_ramas, 3) +
                        parseFloat(guarantor.dobanda_la_zi, 3)
                    ) +
                      " " +
                      guarantor.moneda}
                  </h4>
                </div>

                <div className="ml-2 mr-3 mt-2 flex-align-end">
                  <i
                    className={
                      "stack-interface stack-" +
                      (selected ? "up" : "down") +
                      "-open-big pt-2 pr-2"
                    }
                  ></i>
                </div>
              </div>
            </div>
          </a>
          {selected && (
            <>
              <div className="col-md-12 bg--primary text-right p-4">
                <PayForThisGuarantor guarantor={guarantor} />
              </div>
              <div className="col-md-12 bg--secondary text-left pl-4 py-2 mb-1">
                Nr. contract:{" "}
                <strong>
                  {guarantor.numar_imprumut}/
                  {data_format_ro(guarantor.data_imprumut)}
                </strong>
                <br />
                Tip imprumut: <strong>{guarantor.tip_imprumut}</strong>
                <br />
                Sold initial:{" "}
                <strong>
                  {number_format_ro(guarantor.sold_initial) +
                    " " +
                    guarantor.moneda}{" "}
                </strong>
                <br />
                Sold ramas:{" "}
                <strong>
                  {number_format_ro(guarantor.sold_ramas) +
                    " " +
                    guarantor.moneda}{" "}
                </strong>
                <br />
                Dobanda la zi:{" "}
                <strong>
                  {number_format_ro(guarantor.dobanda_la_zi) +
                    " " +
                    guarantor.moneda}
                </strong>
                <br />
                Ultima depunere:{" "}
                <strong>
                  {data_format_ro(guarantor.ultima_operatie_efectuata)}
                </strong>
                {deadlineExceeded && (
                  <span style={{ color: COLOR_DANGER }}>
                    <br />
                    <strong>Ati depasit termenul final!</strong>
                  </span>
                )}
                {hasDebt && (
                  <strong style={{ color: COLOR_DANGER }}>
                    <br />
                    Restanta: {number_format_ro(debtRemote)} {guarantor.moneda}
                  </strong>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

Guarantor.propTypes = {
  guarantor: PropTypes.object.isRequired,
};

export default Guarantor;
