import Api from "./Api";
import moment from "moment";
import * as Helpers from "../helpers";

export const checkHasExpired = (time) => {
  const check = moment.unix(time);
  return !check.isAfter(moment());
};

export const getTwoFaToken = async () => {
  const config = await Helpers.getConfigFromStorage();
  if (
    config?.token2FA &&
    config?.token2FAExpire &&
    !checkHasExpired(config?.token2FAExpire)
  ) {
    return {
      token: config?.token2FA,
      expireIn: moment.unix(config?.token2FAExpire).format("HH:mm:ss"),
    };
  }

  return null;
};

export const setTwoFAToken = async (token, expireInUnix) => {
  await Helpers.setConfigFromStorage({
    token2FA: token,
    token2FAExpire: expireInUnix,
  });

  return true;
};

export const removeTwoFaToken = async () => {
  await Helpers.removeConfigFromStorage("token2FA");
  await Helpers.removeConfigFromStorage("token2FAExpire");
  return true;
};

export const setExpirePasswordCode = async (expireIn) => {
  await Helpers.setConfigFromStorage({
    expireInCodePasswordReset: expireIn,
  });
  return true;
};

export const getExpirePasswordCode = async () => {
  const config = await Helpers.getConfigFromStorage();
  return config?.expireInCodePasswordReset || undefined;
};

export const removeExpirePasswordCode = async () => {
  await Helpers.removeConfigFromStorage("expireInCodePasswordReset");
  return true;
};

export const checkRemoteCredentials = (data) => {
  return Api({
    method: "POST",
    url: "/users/login",
    data,
  });
};

export const loginWith2fa = (token, code, uid_device) =>
  Api({
    method: "POST",
    url: "/users/check2fa",
    data: {
      token,
      code,
      uid_device,
    },
  });

export const codeTypedForChangePassword = (data_post) => {
  return Api({
    method: "POST",
    url: "/users/CodeTypedForChangePassword",
    data: data_post,
  });
};

export const checkToken = (generateNewToken = false) =>
  Api({
    method: "POST",
    url: "/users/checkToken",
    data: {
      generateNewToken,
    },
  });
