import React from "react";
import Loan from "./loan";
import { number_format_ro } from "../../helpers";

const Loans = ({ loans = [] }) => {
  const total = number_format_ro(
    loans.reduce(
      (previousValue, currentValue) =>
        parseFloat(currentValue.sold_imprumut) +
        parseFloat(currentValue.dobanda_la_zi) +
        parseFloat(previousValue),
      0
    )
  );

  if (loans.length === 0) {
    return null;
  }

  return (
    <div className="cta cta-1 cta--horizontal boxed boxed--border my-0 py-0 px-0 mx-0">
      <div className="row ">
        <div
          className="col-lg-12 col-sm-12 col-md-12 bg--primary"
          style={{ backgroundColor: "#c0392b" }}
        >
          <div className="row">
            <div className="col">
              <h4 className="mb-1 py-2 text-left  ml-4 pl-0">Imprumuturi</h4>
            </div>
            <div className="text-right col">
              <h4 className="mb-1 py-2 mr-4">{total} RON</h4>
            </div>
          </div>
        </div>
      </div>
      {loans.map((each) => (
        <Loan key={each.id} loan={each} />
      ))}
    </div>
  );
};

export default Loans;
