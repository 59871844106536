import React, { useState } from "react";
import * as UserService from "../../services/UserService";
import { COLOR_DANGER } from "../../constants";
import { Sections, Loading } from "../../components";
import { toast } from "react-toastify";

const defaultValue = {
  password: "",
  repeatPassword: "",
  errorPassword: false,
  errorRepeatPassword: false,
};
const ChangePassword = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [passwordObject, setPasswordObject] = useState(defaultValue);

  const validate = () => {
    const { password, repeatPassword } = passwordObject;

    if (password.length < 1) {
      setPasswordObject({ ...passwordObject, errorPassword: true });
      toast.error("Parola este obligatoriu!");
      return false;
    }
    if (password.length < 6) {
      setPasswordObject({ ...passwordObject, errorPassword: true });
      toast.error(
        "Parola are foarte putine caractere, folositi cel putin 8 caractere."
      );
      return false;
    }
    if (password !== repeatPassword) {
      setPasswordObject({ ...passwordObject, errorRepeatPassword: true });
      toast.error("Parola nu corespunde cu repetare parola.");
      return false;
    }

    return true;
  };

  const submitChangePassword = (e) => {
    e.preventDefault();

    if (validate() === false) {
      return false;
    }

    setIsLoading(true);
    UserService.updatePassword(passwordObject.password)
      .then(() => {
        toast.success("Operatiune efectuata cu succes!");
        setIsLoading(false);
        setPasswordObject(defaultValue);
      })
      .catch((err) => {
        setPasswordObject({
          ...passwordObject,
          errorPassword: true,
          errorRepeatPassword: true,
        });
        console.log(err);
        toast.error(err.message || err || "Unknown");
        setIsLoading(false);
      });
  };

  return (
    <>
      <Sections.Title
        title="Schimbare parola"
        subtitle="Ca recomandare, in locul unei parole obisnuite folositi si caractere speciale sau o propozitie cu care sunteti familiarizat, iar pentru un supliment de siguranta, activati si autentificarea dubla daca nu ati facut-o."
      />
      <Sections.Container>
        <div className="row">
          <div className="col-md-12">
            <h6 className="type--uppercase mb-0 pb-0">Schimbare parola</h6>
            <form className="row" onSubmit={submitChangePassword}>
              <div className="col-md-4">
                <input
                  type="password"
                  name="password"
                  placeholder="Parola"
                  value={passwordObject.password}
                  style={
                    passwordObject.errorPassword
                      ? { borderColor: COLOR_DANGER }
                      : {}
                  }
                  onChange={(e) =>
                    setPasswordObject({
                      ...passwordObject,
                      errorPassword: false,
                      password: e.target.value,
                    })
                  }
                />
              </div>
              <div className="col-md-4">
                <input
                  type="password"
                  name="password_repeat"
                  placeholder="Repetare"
                  value={passwordObject.repeatPassword}
                  style={
                    passwordObject.errorRepeatPassword
                      ? { borderColor: COLOR_DANGER }
                      : {}
                  }
                  onChange={(e) =>
                    setPasswordObject({
                      ...passwordObject,
                      errorRepeatPassword: false,
                      repeatPassword: e.target.value,
                    })
                  }
                />
              </div>
              <div className="col-md-3">
                {isLoading ? (
                  <Loading />
                ) : (
                  <button
                    type="submit"
                    className="btn btn--primary type--uppercase mt-1"
                  >
                    Schimba
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
      </Sections.Container>
    </>
  );
};

export default ChangePassword;
