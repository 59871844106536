import { applyMiddleware, createStore } from "redux";
import rootReducer from "./reducers";
import logger from "redux-logger";

let store = createStore(rootReducer);

if (process.env.NODE_ENV !== "production") {
  store = createStore(rootReducer, applyMiddleware(logger));
}

export default store;
