import React from "react";
import { Link } from "react-router-dom";

const Menu = () => {
  const menus = [
    {
      url: "/",
      name: "Financiar",
      icon: "icon-Financial",
      id: 0,
    },

    {
      url: "/tranzactii",
      name: "Tranzactii",
      icon: "icon-Bar-Chart2",
      id: 1,
    },

    {
      url: "/giranti",
      name: "Giranti",
      icon: "icon-Affiliate",
      id: 2,
    },
  ];

  const url = window.location.href;
  const filter = menus.filter((m) => m.url !== "/" && url.indexOf(m.url) > -1);

  const select = filter.length === 1 ? filter[0].id : 0;

  const displayMenus = () => {
    return menus.map((menu) => (
      <div className="col-md-12 col" key={menu.id}>
        <div
          className={
            "text-block boxed boxed--sm boxed--border mb-1 " +
            (menu.id === select ? " bg--primary" : "")
          }
        >
          <Link to={menu.url} className="block">
            <i className={`icon icon--sm block ${menu.icon}`}></i>
            <span className="h5 hidden-xs">{menu.name}</span>
          </Link>
        </div>
      </div>
    ));
  };

  return (
    <>
      {/* <div className="hidden-xs" style={{ height: 53 }} /> */}
      <div className="row">{displayMenus()}</div>
    </>
  );
};

export default Menu;
