import React from "react";
import { DataRagerPicker } from "../../../components";
import PropTypes from "prop-types";
import moment from "moment";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import "./style.css";
const options = [
  {
    value: 7,
    label: "Ultimele 7 zile",
  },
  {
    value: 14,
    label: "Ultimele 2 saptamani",
  },
  {
    value: 30,
    label: "Ultima luna",
  },
  {
    value: 0,
    label: "Alt interval",
  },
];

const Filters = ({ changeFilter }) => {
  const [interval, setInterval] = React.useState(7);
  const [search, setSearch] = React.useState("");
  const [range, setRange] = React.useState({
    data_start: moment().subtract("2", "months").format("YYYY-MM-DD"),
    data_end: moment().format("YYYY-MM-DD"),
  });

  const onSubmit = (ev) => {
    ev.preventDefault();

    if (interval === 0) {
      changeFilter({ ...range, searchingText: search });
    } else {
      changeFilter({
        searchingText: search,
        data_start: moment().subtract(interval, "days").format("YYYY-MM-DD"),
        data_end: moment().format("YYYY-MM-DD"),
      });
    }
  };

  const onSelect = ({ value }) => {
    setInterval(value);
  };

  const setRangeInterval = (rangeProcessing) => {
    const { startDate, endDate } = rangeProcessing;

    setRange({
      data_start: moment(startDate).format("YYYY-MM-DD"),
      data_end: moment(endDate).format("YYYY-MM-DD"),
    });
  };

  return (
    <form onSubmit={onSubmit} className="row">
      <div className="col-md-5">
        <input
          type="text"
          value={search}
          placeholder="Cauta in tranzactii"
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>
      <div className="col-md-4">
        <Dropdown
          options={options}
          onChange={onSelect}
          value={options[1]}
          placeholder="Selectati"
        />
      </div>
      <div className="col-md-3">
        <button type="submit" className="btn btn--primary mt-0">
          Cauta
        </button>
      </div>

      <div className={`col ${interval !== 0 ? "hidden" : "active"}`}>
        <DataRagerPicker setRangeInterval={setRangeInterval} />
      </div>
    </form>
  );
};

Filters.propTypes = {
  changeFilter: PropTypes.func.isRequired,
};

export default Filters;
