import { SET_LOGIN, LOGOUT_SUCCESS } from "./reducers";

export const setLogin = (payload) => ({
  type: SET_LOGIN,
  payload,
});

export const logoutSuccess = () => ({
  type: LOGOUT_SUCCESS,
});
