import React from "react";
import { PulseLoader } from "react-spinners";
import { Logo } from "../../components";
import { useForm } from "react-hook-form";

const InputCode = ({ setInput, message }) => {
  const [loading, setLoading] = React.useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async ({ input }) => {
    const noSpaceInCode = input.replace(/\s/g, "");
    await setInput(noSpaceInCode);
    setLoading(false);
  };

  return (
    <div className="row">
      <div className="col-md-6 col-lg-4">
        <div className="boxed boxed--border boxed--lg text-center">
          <Logo />
          <p className="lead mt-4">
            Introduceti codul primit prin e-mail / SMS.
          </p>
          {message && <p>{message}</p>}
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="mt-2 pt-0"
            noValidate
            autoComplete="off"
          >
            <div className="row mt-1">
              <div className="col-md-12 ">
                <input
                  type="text"
                  style={errors.input ? { borderColor: "#c0392b" } : {}}
                  placeholder="Codul receptionat"
                  {...register("input", {
                    required: "Codul este obligatoriu",
                    minLength: "Codul trebuie sa aiba 6 cifre",
                    maxLength: "Codul trebuie sa aiba 6 cifre",
                  })}
                  defaultValue=""
                />
                {errors.input && (
                  <span style={{ color: "#c0392b" }}>
                    {errors.input.message}
                  </span>
                )}
              </div>
              <div className="col-md-12 mt-2">
                {loading ? (
                  <PulseLoader color="#4a90e2" />
                ) : (
                  <button
                    className="btn btn--primary type--uppercase"
                    type="submit"
                  >
                    Conectare
                  </button>
                )}
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default InputCode;
