import React from "react";

const Logo = () => (
  <a className="block" href="/">
    <img
      alt="Casa de Ajutor Reciproc Metropolitan IFN"
      src="https://membri.carmetropolitan.ro/img/logo-with-description.png"
    />
  </a>
);

export default Logo;
