import React from "react";
import PropTypes from "prop-types";
import { number_format_ro, data_format_ro } from "../../helpers";

const Loan = ({ loan }) => {
  let hasRestanta = false;
  let restanta = 0;
  if (parseInt(loan.rata_restanta_procent) < 0) {
    restanta = parseFloat(loan.rata_restanta_procent, 3);
    hasRestanta = true;
  }

  return (
    <>
      <table className="table--alternate-row">
        <thead>
          <tr>
            <td valign="top" align="left" colSpan="5">
              <strong>
                {loan.nume_fond}
                <br />
                Durata imprumut: {loan.termen} luni - {loan.dobanda_procent} %
                pe an <br />
                Nr. contract: {loan.numar_contract}/
                {data_format_ro(loan.data_imprumut)},<br />#{loan.numar_fond}
              </strong>
            </td>
          </tr>
          <tr className="visible-sm">
            <td colSpan="5" align="right">
              {hasRestanta && (
                <>
                  <span style={{ color: "#c0392b", fontWeight: "bold" }}>
                    Restanta: {number_format_ro(restanta)} {loan.tip_moneda}{" "}
                  </span>
                  <br />
                </>
              )}
              Modificare: {data_format_ro(loan.data_ultima_operatie_depunere)}
            </td>
          </tr>
          <tr className="visible-xs">
            <td colSpan="5" align="left">
              Scadenta: <strong>{data_format_ro(loan.data_scadenta)}</strong>
              <br />
              Plata:{" "}
              <strong>
                {number_format_ro(loan.suma_de_plata)} {loan.tip_moneda}
              </strong>
              <br />
              {hasRestanta && (
                <>
                  <span style={{ color: "#c0392b", fontWeight: "bold" }}>
                    Restanta: {number_format_ro(restanta)} {loan.tip_moneda}{" "}
                  </span>
                  <br />
                </>
              )}
              Modificare:{" "}
              <strong>
                {data_format_ro(loan.data_ultima_operatie_depunere)}
              </strong>
            </td>
          </tr>

          <tr>
            <th>Sold</th>
            <th>Rata</th>
            <th>Dobanda la zi</th>
            <th className="hidden-xs">Scadenta</th>
            <th className="hidden-xs">Plata</th>
            {hasRestanta && (
              <th className="hidden-sm hidden-xs" style={{ color: "#c0392b" }}>
                Restanta
              </th>
            )}
            <th className="hidden-sm hidden-xs">Modificare</th>
          </tr>
        </thead>
        <tbody>
          <tr
            style={
              hasRestanta ? { backgroundColor: "#c0392b", color: "#fff" } : {}
            }
          >
            <td valign="top">
              {number_format_ro(loan.sold_imprumut)} {loan.tip_moneda}
            </td>
            <td valign="top">
              {number_format_ro(loan.rata_lunara_imprumut)} {loan.tip_moneda}
            </td>
            <td valign="top">
              {number_format_ro(loan.dobanda_la_zi)} {loan.tip_moneda}
            </td>

            <td valign="top" className="hidden-xs">
              {data_format_ro(loan.data_scadenta)}
            </td>
            <td valign="top" className="hidden-xs">
              {number_format_ro(loan.suma_de_plata)} {loan.tip_moneda}
            </td>
            {hasRestanta && (
              <td className="hidden-sm hidden-xs" valign="top">
                {number_format_ro(restanta)} {loan.tip_moneda}
              </td>
            )}
            <td className="hidden-sm hidden-xs" valign="top">
              {data_format_ro(loan.data_ultima_operatie_depunere)}
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

Loan.propTypes = {
  loan: PropTypes.object.isRequired,
};
export default Loan;
