import React from "react";
import { Link, Redirect } from "react-router-dom";
import { REDIRECT_TO_DASHBOARD, REDIRECT_TO_LOGIN_INPUT } from ".";
import * as AuthService from "../../services/AuthService";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { setLogin } from "../Global/actions";
import * as Helpers from "../../helpers";
import Wallpaper from "./Wallpaper";
import InputCode from "./InputCode";

const Code2FA = () => {
  const [message, setMessage] = React.useState("");
  const [redirect, setRedirect] = React.useState(null);
  const [tokenFa, setTokenFa] = React.useState(null);

  const dispatch = useDispatch();

  const setInput = async (code) => {
    const noSpaceInCode = code.replace(/\s/g, "");

    const uuidDevice = await Helpers.getUniqueCode();
    try {
      const {
        token,
        expireIn,
        user,
        hasTwoFa = false,
      } = await AuthService.loginWith2fa(tokenFa, noSpaceInCode, uuidDevice);

      dispatch(setLogin({ token, expireIn, user, hasTwoFa }));

      await Helpers.setConfigFromStorage({ token, expireIn, user, hasTwoFa });

      AuthService.removeTwoFaToken();
      setTimeout(() => {
        setRedirect(REDIRECT_TO_DASHBOARD);
      }, 500);
    } catch (err) {
      toast.error(err?.message || err);
    }
  };

  React.useEffect(() => {
    checkCodeIfIsValid();
  }, []);

  const checkCodeIfIsValid = async () => {
    const checking = await AuthService.getTwoFaToken();

    if (checking?.token && checking?.expireIn) {
      setMessage(`Codul trimis va expira la ora: ${checking.expireIn}`);
      setTokenFa(checking.token);
    }
  };

  if (redirect === REDIRECT_TO_LOGIN_INPUT) {
    return <Redirect to="/login" />;
  }

  if (redirect === REDIRECT_TO_DASHBOARD) {
    return <Redirect to="/" />;
  }

  return (
    <Wallpaper>
      <InputCode setInput={setInput} message={message} />
      <div className="pt-2">
        <Link to="/login">Inapoi la conectare</Link>
      </div>
    </Wallpaper>
  );
};

export default Code2FA;
