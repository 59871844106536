import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { number_format_ro } from "../../helpers";
import { COLOR_DANGER } from "../../constants";

const Transaction = (props) => {
  const { rulaj } = props;
  const data = moment(rulaj.data_operatie);

  const monthActual = moment().startOf("month");
  const isOld = monthActual.diff(data, "days") > 0 ? true : false;

  return (
    <>
      <tr>
        <td
          className="bg--primary color--white"
          valign="center"
          align="center"
          style={isOld ? { backgroundColor: COLOR_DANGER } : {}}
        >
          <span style={{ fontSize: 20 }}>{data.format("DD")}</span>
          <br />
          <span style={{ fontSize: 18, fontWeight: "bold" }}>
            {data.format("MMM").toUpperCase()}
          </span>
        </td>
        <td>
          {rulaj.ce_operatie}
          <br />
          {rulaj.numar_fond}
        </td>
        <td>
          {parseInt(rulaj.c_intrari) === 0
            ? number_format_ro(rulaj.c_iesiri)
            : number_format_ro(rulaj.c_intrari)}
          <br />
          {rulaj.moneda}
        </td>
      </tr>
      <tr>
        <td colSpan="3">
          Data operatie:{" "}
          <strong>
            {moment(rulaj.data_operatie_operator).format("DD.MM.YYYY HH:mm:ss")}
          </strong>
          <br />
          Document plata: <strong>{rulaj.mod_plata}</strong> <br />
          {parseInt(rulaj.c_intrari) === 0 ? "Iesire" : "Intrare"}:{" "}
          <strong>
            {parseInt(rulaj.c_intrari) === 0
              ? number_format_ro(rulaj.c_iesiri)
              : number_format_ro(rulaj.c_intrari)}{" "}
            {rulaj.moneda}
          </strong>{" "}
          <br />
          Sold:{" "}
          <strong>
            {number_format_ro(rulaj.c_sold)} {rulaj.moneda}
          </strong>
        </td>
      </tr>
    </>
  );
};

Transaction.propTypes = {
  rulaj: PropTypes.object.isRequired,
};

export default Transaction;
