import React, { useState } from "react";
import Funds from "../Funds";
import Loans from "../Loans";
import Executors from "../Executors";
import { Loading, Container } from "../../components";

import * as FinancialService from "../../services/FinancialService";
import Payment from "../Payment";

const Financial = () => {
  const [data, setData] = useState(null);

  const [loading, setLoading] = useState(true);
  React.useEffect(() => {
    // get finacial once
    getAll();
  }, []);

  const getAll = async () => {
    try {
      const {
        fonduri = [],
        imprumuturi = [],
        debs = [],
        debsGuarantors = [],
      } = await FinancialService.getAll();
      setData({
        funds: fonduri,
        loans: imprumuturi,
        executors: [...debs, ...debsGuarantors],
      });
    } catch (err) {}

    setLoading(false);
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <Container>
      <div className="text-right">
        <Payment list={data} />
      </div>
      <Funds funds={data?.funds} />
      {data?.loans.length > 0 && (
        <>
          <br className="my-2" />
          <Loans loans={data.loans} />
        </>
      )}

      {data?.executors.length > 0 && (
        <>
          <br className="my-2" />
          <Executors executors={data.executors} />
        </>
      )}
    </Container>
  );
};

export default Financial;
