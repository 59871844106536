import React from "react";
const NotFound = () => {
  return (
    <section className="height-100 text-center">
      <div className="container pos-vertical-center">
        <div className="row">
          <div className="col-md-12">
            <h1 className="h1--large">404</h1>
            <p className="lead">Pagina care o cautati nu exista.</p>
            <a href="/">Inapoi la pagina initiala</a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default NotFound;
