import React from "react";
import { Link, Redirect } from "react-router-dom";

import { PulseLoader } from "react-spinners";
import { Logo } from "../../components";
import { toast } from "react-toastify";
import Wallpaper from "./Wallpaper";
import * as UserService from "../../services/UserService";
import * as Helpers from "../../helpers";
import * as AuthService from "../../services/AuthService";
import { REDIRECT_TO_FORGOT_PASSWORD_CODE } from ".";

const LostPassword = () => {
  const [loading, setLoading] = React.useState(false);
  const [input, setInput] = React.useState("");
  const [error, setError] = React.useState(null);
  const [redirect, setRedirect] = React.useState(false);

  React.useEffect(() => {
    checkAlreadyHasCodeToInput();
  }, []);

  const checkAlreadyHasCodeToInput = async () => {
    const expireIn = await AuthService.getExpirePasswordCode();
    if (expireIn && !AuthService.checkHasExpired(expireIn)) {
      setRedirect(REDIRECT_TO_FORGOT_PASSWORD_CODE);
    }
  };

  const onPressed = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const unique_code = await Helpers.getUniqueCode();
      const { message, expireIn } = await UserService.resetPassword(
        input,
        unique_code
      );

      toast.success(message);
      AuthService.setExpirePasswordCode(expireIn);
      setTimeout(() => {
        setRedirect(REDIRECT_TO_FORGOT_PASSWORD_CODE);
      }, 500);
    } catch (err) {
      setError(err);
      toast.error(err);
    } finally {
      setLoading(false);
    }
  };

  if (redirect === REDIRECT_TO_FORGOT_PASSWORD_CODE) {
    return <Redirect to="/code-reset-password" />;
  }

  return (
    <Wallpaper>
      <div className="row">
        <div className="col-md-6 col-lg-4">
          <div className="boxed boxed--border boxed--lg text-center">
            <Logo />
            <p className="lead mt-4">
              Introduceti numarul de telefon sau adresa de mail asociat contului
              dvs.
            </p>
            <form
              onSubmit={onPressed}
              className="mt-2 pt-0"
              noValidate
              autoComplete="off"
            >
              <div className="row mt-1">
                <div className="col-md-12 ">
                  <input
                    type="text"
                    style={error ? { borderColor: "#c0392b" } : {}}
                    placeholder="Telefon / Adresa mail"
                    value={input}
                    onChange={(e) => {
                      setInput(e.target.value);
                      setError("");
                    }}
                  />
                </div>
                <div className="col-md-12 mt-2">
                  {loading ? (
                    <PulseLoader color="#4a90e2" />
                  ) : (
                    <button
                      className="btn btn--primary type--uppercase"
                      type="submit"
                    >
                      Reseteaza contul
                    </button>
                  )}

                  <div className="pt-2">
                    <Link to="/login">Inapoi la conectare</Link>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <Link to="/code-reset-password">Am codul pentru resetare parola</Link>
        </div>
      </div>
    </Wallpaper>
  );
};

export default LostPassword;
