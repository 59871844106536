import React from "react";
import { Link } from "react-router-dom";

const ConfirmarePlata = ({ isAErrorWithPayment = false }) => {
  const m = !isAErrorWithPayment
    ? "Multumim pentru plata efecutata."
    : "Plata nu a fost efectuata.";

  const reason = !isAErrorWithPayment
    ? "Plata a fost efectuata si va fi procesata de un operator in urmatoarea zi lucratoare."
    : "";

  return (
    <section className="text-center">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-10 col-lg-8">
            <h2>{m}</h2>
            <p className="lead">{reason}</p>
          </div>
        </div>
      </div>
      <Link className="btn btn--primary-1 mt-5" to="/">
        <span className="btn__text">Inapoi la pagina principala</span>
      </Link>
    </section>
  );
};

export default ConfirmarePlata;
